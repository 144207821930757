@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: 'Raleway';
}

.ant-menu-item-selected::after, .ant-menu-item:hover::after {
  border-bottom-width: 2px!important;
  border-bottom-color: #ff7f01 !important;
  width: 20px!important;

}

.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after, /* Submenu seleccionado */
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, /* Submenu seleccionado */
.ant-menu-light.ant-menu-horizontal >.ant-submenu-active::after, /* Submenu seleccionado */
.ant-menu-light.ant-menu-horizontal >.ant-submenu-open::after{ /* Submenu seleccionado */
  border-bottom-width: 2px!important;
  border-bottom-color: #ff7f01 !important;

}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{ /* Submenu seleccionado */
  color: #ff7f01;
}

.ant-menu-light>.ant-menu .ant-menu-item-selected, /* Item seleccionado */
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled), /* Item seleccionado */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, /* Día seleccionado */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, /* Día de inicio del rango */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{ /* Día de fin del rango */
    background-color: #ff7f01;
    color: #fff;
}

.ant-picker-dropdown .ant-picker-today-btn { /* Botón de hoy */
  color: #ff7f01;
}


.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before { /* Día de hoy */
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  border: 2px solid #ff7f01;
  border-radius: 4px;
  content: "";
}

.ant-input-outlined.ant-input-disabled, .ant-input-outlined[disabled],/* Input deshabilitado */
.ant-input-number-disabled .ant-input-number-input,/* Input Number deshabilitado */
.ant-select-borderless.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector,/* Select  deshabilitado */
.ant-picker .ant-picker-input >input[disabled]{/* Input Date deshabilitado */
  color: #000;
}

.input_form_textarea::placeholder, /* Placeholder del textarea */
.input_form::placeholder, /* Placeholder del input */
.ant-input-number-input::placeholder, /* Placeholder del input number */
.ant-select-selection-placeholder, /* Placeholder del select */
.ant-picker-input >input::placeholder{ /* Placeholder del input date */
  font-size: 12px;
}
.ant-pagination .ant-pagination-item-active{
  background-color: #F2F4F7;
  border-color: #ffffff00;
  border-radius: 50px;
}

.ant-pagination .ant-pagination-item-active:hover ,
.ant-pagination-item:not(.ant-pagination-item-active):hover {
  background-color: #F2F4F7;
  border-color: #ffffff00;
  border-radius: 50px;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  min-width: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination .ant-pagination-item a {
  padding: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-next,
.ant-pagination.ant-pagination-mini .ant-pagination-prev{
  line-height: 20px;
  font-size: 14px;
}

.cam_profile span{
  width: 30px;
  height: 30px;
  background-color: #07004e;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 85px;
  display: flex;
  justify-content: center;
}

.card-table .ant-card-body {
 height: calc(100vh - 150px);
};

.card-table-collapse .ant-card-body {
  height: calc(100vh - 110px);
}
/* 
.border-radius-20-without-left{
  border: 1px solid #f0f0f0;
  border-right-width: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.border-radius-20-without-right{
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.border-top-bottom{
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
} */

/* Estilos para pantallas grandes */


.body_row_div >tr >td {
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}

.first-column {
  border-left: 1px solid #f0f0f0;
  border-radius: 10px 0px 0px 10px;
}

.last-column {
  border-right: 1px solid #f0f0f0;
  border-radius: 0px 10px 10px 0px;
  /* padding: 0px; */
}

.ant-descriptions .ant-descriptions-item-container {
  flex-direction: column;
}

.ant-table .ant-table-header {
  border-radius: 15px 15px 15px 15px!important;
  /* margin-bottom: 5px; */
}

.data-table-header-index{
  flex: 0 0!important;
}
.input::placeholder { /* Cambia el color del placeholder */
  font-style: italic; 
  font-size: 10px;
}

.ant-table-wrapper { /* Estilos para la tabla */
  overflow-x: auto;  
}

.ant-form-item .ant-form-item-explain-connected {
  /* width: 67%; */
  /* max-width:220px */
}

.header_menu_dropdown{
  display: none;
}

@media (max-width: 1380px) { /* Estilos para pantallas grandes */
  .table_custom{
    max-height: calc(-292px + 100vh)!important;
    }




}

/* Estilos para pantallas medianas */
@media (min-width: 768px) and (max-width: 1199px) { 







}

/* Estilos para pantallas pequeñas */
@media (max-width: 767px) {


  .input_form_textarea{
    margin-top: 10px;
    /* width: 220px!important; */
  }





  .flex_form{
    gap: 0px !important;
  }
  .header_menu{
    display: none;
  }
  .header_menu_dropdown{
    display:inline
  }
  .header_menu_dropdown .ant-btn-compact-first-item{
    display: none;
  }
  .header_menu_dropdown .ant-btn-compact-last-item{
    margin-top: 5px;
   border:0px;
  }
  .header_menu_dropdown .ant-btn-default {
    box-shadow: 0px 0px 0px 0px;
  }
  .anticon .anticon-ellipsis{
    display: none;
  }
}

/* Estilos para pantallas extra pequeñas */
@media screen and (max-width: 575px){
  .title_table_addButton{
    justify-content: flex-start!important;
  }
  .title_header_filter{
    font-size: 20px!important;
  }
  .table_custom{
  max-height: calc(-375px + 100vh)!important;
  }
}

.input_form {
  margin-bottom: 0px;
}

.ant-form-item-explain-error {
  font-size: 12px;
  padding-left: 10px;
}

.ant-select-arrow, .ant-picker-suffix {
  color: #05004e !important;
  font-weight: bold !important;
}

/** Estilos del loading **/

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-image {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.anticon.ant-input-password-icon > svg {
  color: #05004e;
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon,
.ant-input-number-outlined,
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon,
.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled),
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled .ant-input-number-group-addon,
.ant-input-number-outlined.ant-input-number-disabled:hover,
.ant-input-number-outlined.ant-input-number-disabled,
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled .ant-input-number-group-addon:hover{
  background-color: #f0f0f000;
  border: 1px solid #f0f0f000;
}
.ant-input-number .ant-input-number-input{
  height: 35px;
}
